"use client"

import { useAuth, useSession, useUser } from "@clerk/nextjs"
import {
  AnalyticsTracker,
  BackendApi,
  backendApiCall,
  BackendEndpointDef,
  ClickIdType,
  cookieNameFbClickId,
  cookieNameGbraidClickId,
  cookieNameGoogleClickId,
  cookieNameWbraidClickId,
  JwtBackendTemplate,
} from "@store-platform/backend/common"
import { hasRole } from "@store-platform/utils"
import { useCallback, useEffect } from "react"
import { create } from "zustand"
import {
  trackClientEvent,
  trackClientIdentify,
  trackPageView,
} from "./analytics"
import {
  getAnonIdCookie,
  getClickIdCookie,
  getFbBrowserIdCookie,
  setClickIdCookie,
  setFbClickIdCookie,
} from "./cookies"

const clickIdCookieSetters: Record<ClickIdType, (id: string) => string> = {
  fb: setFbClickIdCookie,
  google: setClickIdCookie(cookieNameGoogleClickId),
  gbraid: setClickIdCookie(cookieNameGbraidClickId),
  wbraid: setClickIdCookie(cookieNameWbraidClickId),
} as const

type Store = {
  ready: boolean
  anonymousId?: string | undefined
  fbBrowserId?: string | undefined
  setAnonymousId: (id: string | undefined) => void
  setFbBrowserId: (id: string | undefined) => void
  clickIds: Record<ClickIdType, string | undefined>
}

type Actions = {
  setReady: (ready: boolean) => void
  getClickId: (id: ClickIdType) => string | undefined
  setClickId: (id: ClickIdType, value: string | undefined) => void
  applyClickId: (id: ClickIdType, value: string) => void
}

const useAnalyticsTrackers = create<Store & Actions>((set, getState) => ({
  ready: false,
  anonymousId: undefined,
  fbBrowserId: undefined,
  clickIds: {
    fb: undefined,
    google: undefined,
    gbraid: undefined,
    wbraid: undefined,
  },
  setAnonymousId: (id: string | undefined) => set({ anonymousId: id }),
  setFbBrowserId: (id: string | undefined) => set({ fbBrowserId: id }),
  setReady: (ready: boolean) => set({ ready }),
  getClickId: (id: ClickIdType) => getState().clickIds[id],
  setClickId: (id: ClickIdType, value: string | undefined) => {
    set({ clickIds: { ...getState().clickIds, [id]: value } })
  },
  applyClickId: (id: ClickIdType, value: string) => {
    const cookieValue = clickIdCookieSetters[id](value)
    set({ clickIds: { ...getState().clickIds, [id]: cookieValue } })
  },
}))

const useTrackingCookies = () => {
  const {
    anonymousId,
    fbBrowserId,
    setAnonymousId,
    setFbBrowserId,
    clickIds,
    setClickId,
  } = useAnalyticsTrackers()

  useEffect(() => {
    if (!anonymousId) setAnonymousId(getAnonIdCookie())
  }, [anonymousId])

  useEffect(() => {
    if (!fbBrowserId) setFbBrowserId(getFbBrowserIdCookie())
  }, [fbBrowserId])

  useEffect(() => {
    if (!clickIds["fb"]) setClickId("fb", getClickIdCookie(cookieNameFbClickId))
  }, [clickIds["fb"]])

  useEffect(() => {
    if (!clickIds["google"])
      setClickId("google", getClickIdCookie(cookieNameGoogleClickId))
  }, [clickIds["google"]])

  useEffect(() => {
    if (!clickIds["gbraid"])
      setClickId("gbraid", getClickIdCookie(cookieNameGbraidClickId))
  }, [clickIds["gbraid"]])

  useEffect(() => {
    if (!clickIds["wbraid"])
      setClickId("wbraid", getClickIdCookie(cookieNameWbraidClickId))
  }, [clickIds["wbraid"]])
}

export const useAnalytics = () => {
  const { session, isLoaded: isSessionLoaded } = useSession()
  const { ready, anonymousId, fbBrowserId, setReady, clickIds, applyClickId } =
    useAnalyticsTrackers()

  useTrackingCookies()

  useEffect(() => {
    setReady(isSessionLoaded && anonymousId !== undefined)
  }, [isSessionLoaded, anonymousId])

  const trackEvent = useCallback(
    (tracker: AnalyticsTracker) => {
      trackClientEvent(
        {
          id: session?.user.externalId,
          anonymousId,
          fbBrowserId,
          clickIds,
        },
        tracker,
      ).then()
    },
    [session?.user.externalId, anonymousId, fbBrowserId, clickIds],
  )

  const trackPage = useCallback(
    (pathname: string, search?: URLSearchParams) => {
      trackPageView(
        {
          id: session?.user.externalId,
          anonymousId,
          fbBrowserId,
          clickIds,
        },
        pathname,
        search,
      ).then()
    },
    [session?.user.externalId, anonymousId, fbBrowserId, clickIds],
  )

  const trackIdentify = useCallback(() => {
    trackClientIdentify({
      id: session?.user.externalId,
      anonymousId,
      fbBrowserId,
      clickIds,
    }).then()
  }, [session?.user.externalId, anonymousId, fbBrowserId, clickIds])

  return {
    trackerReady: ready,
    trackEvent,
    trackPage,
    trackIdentify,
    rawFbClickId:
      clickIds["fb"]?.split(".")[clickIds["fb"].split(".")?.length - 1],
    clickIds: clickIds,
    trackClickId: applyClickId,
  }
}

export const useAnonAnalytics = () => {
  const { anonymousId, fbBrowserId, clickIds, applyClickId } =
    useAnalyticsTrackers()

  useTrackingCookies()

  const trackEvent = useCallback(
    (tracker: AnalyticsTracker) => {
      if (!anonymousId) return
      trackClientEvent(
        {
          id: undefined,
          anonymousId,
          fbBrowserId,
          clickIds,
        },
        tracker,
      ).then()
    },
    [anonymousId, fbBrowserId, clickIds],
  )

  const trackPage = useCallback(
    (pathname: string, search?: URLSearchParams) => {
      if (!anonymousId) return
      trackPageView(
        {
          id: undefined,
          anonymousId,
          fbBrowserId,
          clickIds,
        },
        pathname,
        search,
      ).then()
    },
    [anonymousId, fbBrowserId, clickIds],
  )

  return {
    trackerReady: anonymousId !== undefined,
    anonymousId,
    trackEvent,
    trackPage,
    rawFbClickId:
      clickIds["fb"]?.split(".")[clickIds["fb"].split(".")?.length - 1],
    clickIds: clickIds,
    trackClickId: applyClickId,
  }
}

export const useBackendApi = (): BackendApi => {
  const { sessionId, getToken } = useAuth()
  const { anonymousId, fbBrowserId, clickIds } = useAnalyticsTrackers()

  return useCallback(
    async function <TData, TError = undefined>(
      def: BackendEndpointDef<TData, TError>,
    ) {
      const headers: Record<string, string> = {
        "X-Custom-Referer": window.location.href,
      }

      if (anonymousId) headers["X-Anon-Id"] = anonymousId
      if (fbBrowserId) headers["X-Fb-Browser-Id"] = fbBrowserId
      if (clickIds)
        headers["X-Click-Ids"] = JSON.stringify(
          Object.fromEntries(Object.entries(clickIds).filter(([, v]) => !!v)),
        )

      return backendApiCall<TData, TError>(
        def,
        sessionId ? await getToken({ template: JwtBackendTemplate }) : null,
        { headers },
      )
    },
    [sessionId, getToken, anonymousId, fbBrowserId, clickIds],
  )
}

export const useRole = () => {
  const { user, isSignedIn } = useUser()
  return isSignedIn
    ? {
        isUser: true,
        isDev: hasRole(user.publicMetadata, "developer"),
        isAdmin: hasRole(user.publicMetadata, "admin"),
      }
    : {
        isUser: false,
        isDev: false,
        isAdmin: false,
      }
}
