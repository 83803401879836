import {
  ApiError,
  BackendApi,
  BackendEndpointDef,
  BackendResponse,
} from "./types"
import urlJoin from "url-join"
import {
  camelCaseKeysRecursive,
  snakeCaseKeysRecursive,
} from "@store-platform/utils"
import { snakeCase } from "lodash-es"

export const JwtBackendTemplate = "backend-api"

export const backendApiRootUrl = process.env["NEXT_PUBLIC_BACKEND_API_ROOT_URL"]
if (!backendApiRootUrl)
  throw new Error("NEXT_PUBLIC_BACKEND_API_ROOT_URL not set")

const protectedApiKey = process.env["INTERNAL_API_KEY"] ?? ""
export const cookieNameAnonymousId = "__store_app_anonymous_id"
export const cookieNameFbClickId = "_fbc"
export const cookieNameFbBrowserId = "_fbp"
export const cookieNameGoogleClickId = "_gclid"
export const cookieNameGbraidClickId = "_gbraid"
export const cookieNameWbraidClickId = "_wbraid"

const logging = (process.env["LOG_BACKEND_API_TRAFFIC"] || "").split(",")
const siteId = process.env["NEXT_PUBLIC_SITE_ID"] || ""

const stringifyParams = (params?: { [key: string]: any }) => {
  if (!params) return ""
  const stringifiedParams = new URLSearchParams()
  for (const key in params) {
    if (Array.isArray(params[key]) && params[key].length > 0) {
      for (const value of params[key]) {
        stringifiedParams.append(key, value)
      }
    } else {
      stringifiedParams.append(key, params[key])
    }
  }
  return `?${stringifiedParams.toString()}`
}

function prepJsonBody(data: any) {
  return JSON.stringify(snakeCaseKeysRecursive(data))
}

function prepFormBody(data: any) {
  const formData = new FormData()
  for (const key in data) {
    if (Array.isArray(data[key])) {
      for (const value of data[key]) {
        formData.append(snakeCase(key), value)
      }
    } else {
      formData.append(snakeCase(key), data[key])
    }
  }
  return formData
}

type BackendApiOptions = {
  frontendProtected?: boolean
  headers?: Record<string, string>
}

export const backendApiCall = async <TData, TError = ApiError>(
  def: BackendEndpointDef<TData, TError>,
  authToken?: string | null,
  options?: BackendApiOptions,
): BackendResponse<TData, TError> => {
  const headers: HeadersInit = options?.headers ?? {}

  if (def.options?.form !== true) {
    headers["Content-Type"] = "application/json"
  }

  if (siteId) {
    headers["X-Site-Id"] = siteId
  }

  if (options?.frontendProtected) {
    headers["x-api-key"] = protectedApiKey
  } else {
    if (def.options?.authenticated !== false && authToken) {
      headers["Authorization"] = `Bearer ${authToken}`
    }
  }

  const path = options?.frontendProtected
    ? urlJoin("protected", def.path)
    : def.path

  let response: Response
  try {
    response = await fetch(
      urlJoin(backendApiRootUrl, path, stringifyParams(def?.queryParams)),
      {
        method: def.method.toUpperCase(),
        headers,
        body: def.data
          ? def.options?.form
            ? prepFormBody(def.data)
            : prepJsonBody(def.data)
          : undefined,
        cache: def.options?.cache,
        options: def.options?.next ? { next: def.options.next } : undefined,
      } as RequestInit,
    )
  } catch (error) {
    throw new ApiError("Server not responding", 503)
  }
  const text = await response.text()

  let responseData: any
  try {
    responseData = camelCaseKeysRecursive(JSON.parse(text))
  } catch {
    responseData = text
  }

  if (!response.ok) {
    const error = new ApiError<TError>(
      responseData.message || "Unknown error",
      response.status,
      responseData.code ?? undefined,
      responseData.details ?? undefined,
    )
    def.on?.error?.(error)
    return { data: null, error }
  }

  def.on?.success?.(responseData)

  return { data: responseData, error: null }
}

export const protectedRoute: BackendApi = async <TData, TError>(
  def: BackendEndpointDef<TData, TError>,
) =>
  backendApiCall<TData, TError>(def, null, {
    frontendProtected: true,
  })

export const backendApi = async <TData, TError>(
  api: BackendApi,
  endpoint: BackendEndpointDef<TData, TError>,
): BackendResponse<TData, TError> => api(endpoint)

export const backendApiTransformed = async <
  TData,
  TTransformed,
  TError = undefined,
>(
  api: BackendApi,
  endpoint: BackendEndpointDef<TData, TError>,
  transform: (data: TData) => TTransformed,
): BackendResponse<TTransformed, TError> => {
  const response = await backendApi(api, endpoint)

  if (response.error) return response

  return { data: transform(response.data), error: null }
}
