"use client"

import {
  cookieNameAnonymousId,
  cookieNameFbBrowserId,
  cookieNameFbClickId,
  cookieNameGoogleClickId,
} from "@store-platform/backend/common"

const cookieDomain = process.env.NEXT_PUBLIC_COOKIE_DOMAIN || ""

const cookieRegex = (cookieName: string) =>
  new RegExp(`(^| )${cookieName}=([^;]+)`)

function generateRandomString(length = 32, alpha = true): string {
  const characters = alpha
    ? "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
    : "0123456789"
  let result = ""
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

function setAnonIdCookie() {
  const date = new Date()
  date.setTime(date.getTime() + 365 * 24 * 60 * 60 * 1000)
  const expires = `expires=${date.toUTCString()}`
  const id = generateRandomString()
  document.cookie = `${cookieNameAnonymousId}=${id};${expires};path=/; domain=${cookieDomain}`
  return id
}

export function getAnonIdCookie() {
  const cookieMatch = document.cookie.match(cookieRegex(cookieNameAnonymousId))
  if (cookieMatch && !cookieMatch[2].match(/^[a-z0-9]+$/i)) {
    // Reset cookie if old cookie format (can be cleaned up on Oct, 26, 2025)
    return setAnonIdCookie()
  }
  return cookieMatch?.[2] || setAnonIdCookie()
}

function setFbBrowserIdCookie() {
  const date = new Date()
  date.setTime(date.getTime() + 365 * 24 * 60 * 60 * 1000)
  const expires = `expires=${date.toUTCString()}`
  const fbp = `fb.1.${date.getTime()}.${generateRandomString(9, false)}`
  document.cookie = `${cookieNameFbBrowserId}=${fbp};${expires};path=/; domain=${cookieDomain}`
  return fbp
}

export function getFbBrowserIdCookie() {
  const cookieMatch = document.cookie.match(cookieRegex(cookieNameFbBrowserId))
  return cookieMatch?.[2] || setFbBrowserIdCookie()
}

export function setFbClickIdCookie(clickId: string) {
  const date = new Date()
  date.setTime(date.getTime() + 90 * 24 * 60 * 60 * 1000)
  const expires = `expires=${date.toUTCString()}`
  const fbc = `fb.1.${date.getTime()}.${clickId}`
  document.cookie = `${cookieNameFbClickId}=${fbc};${expires};path=/; domain=${cookieDomain}`
  return fbc
}

export function getFbClickIdCookie() {
  const cookieMatch = document.cookie.match(cookieRegex(cookieNameFbClickId))
  return cookieMatch?.[2]
}

export const setClickIdCookie = (cookieName: string) => (clickId: string) => {
  const date = new Date()
  date.setTime(date.getTime() + 90 * 24 * 60 * 60 * 1000)
  const expires = `expires=${date.toUTCString()}`
  document.cookie = `${cookieName}=${clickId};${expires};path=/; domain=${cookieDomain}`
  return clickId
}

export function getClickIdCookie(cookieName: string) {
  const cookieMatch = document.cookie.match(cookieRegex(cookieName))
  return cookieMatch?.[2]
}

export function getGoogleClickIdCookie() {
  const cookieMatch = document.cookie.match(
    cookieRegex(cookieNameGoogleClickId),
  )
  return cookieMatch?.[2]
}
